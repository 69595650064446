// 打印类属性、方法定义
/* eslint-disable */

export function debounce(fn, delay=300) {
  let timer = null;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}


