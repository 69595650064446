<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form" selfUpdate>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="回复状态" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="status">
            <a-radio-group v-model="form.reply_status" @change="changeStatus">
              <a-radio-button value="">
                全部
              </a-radio-button>
              <a-radio-button value="0">
                待处理
              </a-radio-button>
              <a-radio-button value="1">
                已回复
              </a-radio-button>
              <!-- <a-radio-button :value="item.value" :key='item.value' v-for="(item,index) in status">
                {{item.label}}
              </a-radio-button> -->



            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6" v-if="token.role=='super'">
          <a-form-model-item label="订单状态" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="order_status">
            <a-select v-model="form.order_status" placeholder="请选择订单...">
              <a-select-option value="">
                全部
              </a-select-option>
              <a-select-option :value="item.value" v-for="(item,index) in status" :key="item.value">
                {{item.label}}
              </a-select-option>
              <!-- <a-select-option value="5">
                完成
              </a-select-option>
              <a-select-option value="6">
                弃单
              </a-select-option> -->

            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6" v-if="token.role=='super'">
          <a-form-model-item label="客服名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="user_id">
            <a-select v-model="form.user_id" placeholder="请选择客服..." :options='userList' allowClear
              @onchange="changeValue($event.target.value,'obj','form','user_id')">
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="domain">
            <a-input :value="form.domain" placeholder="请输入域名..."
              @input="changeValue($event.target.value,'obj','form','domain')" />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="邮箱" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="email">
            <a-textarea :auto-size="{ minRows: 1 }" :value="form.email" placeholder="请输入邮箱..."
              @input="changeValue($event.target.value,'obj','form','email')" />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="开始时间" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="start_time">
            <a-date-picker format="YYYY-MM-DD HH:mm:ss" show-time v-model="form.start_time"
              @change="onChangeStartTime" />

          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="结束时间" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="end_time">
            <a-date-picker format="YYYY-MM-DD HH:mm:ss" show-time v-model="form.end_time" @change="onChangeEndTime" />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="订单ID" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="order_id">
            <a-input :value="form.order_id" placeholder="请输入订单ID..."
              @input="changeValue($event.target.value,'obj','form','order_id')" />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="邮件内容" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="content">
            <a-textarea :auto-size="{ minRows: 1,}" :value="form.content" placeholder="请输入邮件内容..."
              @input="changeValue($event.target.value,'obj','form','content')" />
          </a-form-model-item>
        </a-col>




        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button style='margin-right:12px' type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>


    <div style="display:flex;align-items: flex-start;display: flex;height: 100%;">

      <div class="mainContent" style="width:66%;">

        <div class="tableHeader">
          <div class="title">查询表格</div>

        </div>

        <!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,getCheckboxProps:getCheckboxProps }" -->
        <!-- 上面这行选择框的暂时去掉，省空间 -->
        <a-table :size="tableSize" :columns="columns" :row-key="record => record.id" :data-source="data"
          :pagination="pagination" :loading="loading" bordered @change="handleTableChange" :customRow="customRow"
          :rowClassName="record => {
            if(record.id ==selectId &&record.is_top==1) return 'is_top clickRowStyle';
            if (record.id ==selectId) return 'clickRowStyle';
            if (record.is_top==1) return 'is_top';
          }">
          <!-- 客户邮箱 -->
          <template slot="customer_email" slot-scope="data,record">
            <div v-if="record.editEmail"
              style="display: flex;flex-direction: column;align-items: center;justify-content: space-around;align-items: center;">
              <a-input style="" placeholder="" v-model="record.customer_email" />

              <a-button size="small" class="greenBtn" type="primary" :loading="record.emailIconLoading"
                @click="onSaveEmail(record)">保存</a-button>
            </div>
            <div v-else="!record.editEmail"
              style="display: flex;flex-direction: column;align-items: center;justify-content: space-around;align-items: center;">
              <p style="margin-bottom:0;width:100%">{{record.customer_email}}</p>
              <a-button size="small" type="primary" @click="onEditEmail(record)">编辑</a-button>
            </div>
          </template>
          <!-- 客戶名稱 -->
          <template slot="history" slot-scope="data,record">
            {{record.history[record.history.length-1].name}}
          </template>
          <!-- 邮件状态 -->
          <template slot="status" slot-scope="data,record">
            <a-tag v-if="record.reply_status==1 ||token.role!='super'" :color="statusColor[record.reply_status]"
              style="margin-right:0">
              {{record.reply_status|statusFilter}}
            </a-tag>
            <a-popconfirm title="是否移除该条信息?" @confirm="() => notHandled(record)" style="flex-grow: 20;"
              v-else="record.reply_status!=1 && token.role=='super'">
              <a-button size="small" class="greenBtn" type="primary" :loading="record.emailStatusLoading">不处理</a-button>
            </a-popconfirm>

          </template>
          <!-- 订单状态 -->
          <template slot="order_status" slot-scope="data,record">
            <a-tag :color="statusColor[record.order_status]" style="margin-right:0">
              {{record.order_status|order_statusFilter}}
            </a-tag>
          </template>



          <!-- 操作单元格 -->
          <template slot="操作" slot-scope="data">

            <span v-if='data.status!="7"' style="width: 160px;
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            justify-content: space-between;">

              <!-- <a-button size="small" type="primary"  @click="showDrawer(data)">邮件详情</a-button> -->
              <a-button size="small" type="primary" @click="onEditStatus(data)"
                :disabled="data.status!=1">设置状态</a-button>

              <a-button size="small" v-if='data.status=="1"' type="primary" @click="onSee(data)">订单详情</a-button>
              <a-button size="small" v-if='data.status=="0"' type="primary" class="yellowBtn"
                @click="onSetOrder(data)">关联订单</a-button>

              <a-popconfirm title="是否删除该条信息?" @confirm="() => onDelete(data)" style="flex-grow: 20;"
                v-if="token.role=='super'">
                <a-button size="small" type="danger" ghost>删除</a-button>
              </a-popconfirm>

            </span>

          </template>
        </a-table>

      </div>

      <div
        style="width:calc(34% - 12px);z-index:1001;background:#fff;padding:12px;margin-left:12px;    position: sticky;top: 76px;">
        <a-descriptions title="" bordered :column="{ xxl: 2, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }" class="lyqTableBox">
          <a-descriptions-item label="客户名称">
            <div v-if="emailData.length>0">{{emailData[(emailData.length-1)].name}}</div>
          </a-descriptions-item>
          <a-descriptions-item label="客户邮箱">
            <div v-if="emailData.length>0">{{emailData[(emailData.length-1)].sender_email}}</div>
          </a-descriptions-item>

          <a-descriptions-item label="最新邮件">
            <div style="max-height:calc(100vh - 300px);overflow: auto;" v-if="emailData.length>0">
              <a-collapse default-active-key="0" :bordered="false">
                <a-collapse-panel v-for="(item,index) in emailData" style="margin:4px 0;background:#fff;" :key="index"
                  :showArrow="false" :style="{ background:item.email_type=='received'?'#fff6dd73':'#ccd2ff30'}">
                  <div slot="header"
                    style="font-weight: 600;display: flex;justify-content: space-between;color: rgba(0, 0, 0, 0.65);">
                    <div>{{item.name}}<br>
                      <p style="margin:6px 0 0 0px">{{item.email_subject}}</p>
                    </div>
                    <p style="margin-left: 12px;">{{item.email_time}}</p>

                  </div>
                  <div style="padding:12px;display: block;background:#fff;overflow: auto;">
                    <a-dropdown v-if="item.attachment_url.length>0">
                      <a class="ant-dropdown-link" style='margin-bottom: 12px;display: block;'>
                        下载附件 <a-icon type="down" />
                      </a>
                      <a-menu slot="overlay">
                        <a-menu-item v-for="(i,z) in item.attachment_url" :key="z">
                          <a :href="i" target='_blank'>{{i}}</a>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                    <div v-html="item.message"></div>
                  </div>
                </a-collapse-panel>

              </a-collapse>
            </div>


          </a-descriptions-item>


        </a-descriptions>

        <a-button style='width: 120px;padding: 6px 16px;height: auto;float: right;margin-top: 10px;' size="small"
          type="primary" @click="onSendEmail(data)" :disabled="transaction_id==''">回复</a-button>



      </div>

    </div>


    <!-- 订单详情 -->
    <a-modal v-model="seeModalVisible" title="订单详情" width="1200px" @ok="saveQuantity" @cancel="cancelQuantity"
      :maskClosable="false" class="seeModal leftModal">
      <div style="max-height: 600px;overflow: auto;">
        <div style="display:flex;justify-content: space-between;">
          <h3>客户详情：</h3>
          <div>
            <span v-show="!seeData.editAddress" style="max-width: 300px;display: inline-block;">
              <a-button type="link" @click="editAddress">编辑</a-button>
            </span>
            <div v-show="seeData.editAddress" style="display:flex">
              <a-button type="link" @click="cancelAddress" style="margin-right:-10px">取消</a-button>
              <a-button type="link" @click="saveAddress">保存</a-button>
            </div>


          </div>
        </div>
        <a-descriptions title="" bordered :column="{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }"
          style="margin-bottom:40px;">
          <a-descriptions-item label="订单ID">
            {{seeData.order_id}}
          </a-descriptions-item>
          <a-descriptions-item label="订单金额">
            {{seeData.order_price}}
          </a-descriptions-item>
          <a-descriptions-item label="订单时间">
            {{seeData.order_time}}
          </a-descriptions-item>
          <a-descriptions-item label="客户名称">


            <span v-show="!seeData.editAddress" style="max-width: 300px;display: inline-block;">
              {{seeData.customer_name}}
            </span>
            <div v-show="seeData.editAddress" style="display:flex">
              <a-input :auto-size="{ minRows: 1,}" :value="editNameData"
                @input="changeValue($event.target.value,'obj','editNameData')" placeholder="请输入客户名称..." />
            </div>

          </a-descriptions-item>
          <a-descriptions-item label="客户邮箱">
            {{seeData.customer_email}}
          </a-descriptions-item>
          <a-descriptions-item label="客户电话">
            <span v-show="!seeData.editAddress" style="max-width: 300px;display: inline-block;">
              {{seeData.customer_phone}}
            </span>
            <div v-show="seeData.editAddress" style="display:flex">
              <a-input :auto-size="{ minRows: 1,}" :value="editPhoneData"
                @input="changeValue($event.target.value,'obj','editPhoneData')" placeholder="请输入客户电话..." />
            </div>
          </a-descriptions-item>
          <a-descriptions-item label="客户地址">
            <span v-show="!seeData.editAddress" style="max-width: 300px;display: inline-block;">
              {{seeData.customer_address}}
            </span>
            <div v-show="seeData.editAddress" style="display:flex">
              <a-textarea :auto-size="{ minRows: 1,}" :value="editAddressData"
                @input="changeValue($event.target.value,'obj','editAddressData')" placeholder="请输入地址..." />
            </div>
          </a-descriptions-item>

          <a-descriptions-item label="客户IP" :span="2">
            {{seeData.customer_ip}}
          </a-descriptions-item>
          <a-descriptions-item label="域名" :span="1">
            <a :href="'https://'+seeData.site_url" target="_blank"> {{seeData.site_url}}</a>
          </a-descriptions-item>

          <a-descriptions-item label="订单备注" :span="3">
            {{seeData.customer_remark}}
          </a-descriptions-item>

        </a-descriptions>

        <h3>订单详情：</h3>
        <a-table :size="tableSize" :columns="columnsSee"
          :row-key="(record,index) => {return record.product_id+(index+'')}" :data-source="seeData.products"
          :pagination="false" :footer="null" bordered>

          <template slot="img" slot-scope="data,record">
            <a :href="record.product_image" target="_blank"><img style="max-width:120px"
                :src="record.product_image"></a>
          </template>
          <template slot="product_name" slot-scope="data,record">
            <a :href="record.product_link" target="_blank">{{record.product_name}}</a>
          </template>

          <template slot="product_quantity" slot-scope="data,record,index">
            <div style="display: flex;align-items: center;justify-content: center;">
              <a-input-number v-model="record.product_quantity" placeholder="" :min='1'
                style="text-align:center;width:60px" />

              <a-popconfirm title="是否删除该产品?" @confirm="() => deleteQuantity(index)" v-if="seeData.products.length>1">
                <a-button size="small" type="danger" style="margin: 4px 2px;" ghost>删除</a-button>
              </a-popconfirm>

            </div>
          </template>


        </a-table>
      </div>

    </a-modal>

    <!-- 设置状态 -->
    <a-modal title="设置订单状态" :visible="editStatusModalVisible" :confirm-loading="editStatusConfirmLoading"
      @ok="saveStatus" @cancel="cancelStatus">
      <a-form-model ref="editStatus" :model="editStatusForm" :rules="editAssignFormRules" :label-col="{span:7}"
        :wrapper-col="{span:17}">


        <a-form-model-item label="状态" prop="status">
          <a-radio-group v-model="editStatusForm.status">
            <a-radio style="display:block;line-height:34px;" :value="item.value" v-for="(item) in status"
              :key="item.value">
              {{item.label}}
            </a-radio>

          </a-radio-group>

        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 关联订单 -->
    <a-modal title="关联订单" :visible="setOrderVisible" :confirm-loading="setOrderLoading" @ok="setOrderCancel"
      @cancel="setOrderCancel" :footer="null" width="1200px" class="seeModal leftModal">

      <a-form-model ref="setOrder" :model="setOrderForm">
        <a-col :sm="24" :md="12" :lg="8">
          <a-form-model-item label="订单ID" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="order_id">
            <a-input v-model="setOrderForm.order_id" placeholder="请输入订单ID..." />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8">
          <a-form-model-item label="域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="site_url">
            <a-input v-model="setOrderForm.site_url" placeholder="请输入域名..." />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">
            <a-button style='margin-right:12px' type="primary" @click="searchOrder">
              搜索
            </a-button>
            <a-button @click="resetOrder">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-form-model>
      <div style="display:flex;width:100%">
      </div>

      <a-table :size="tableSize" :columns="orderColumns" :row-key="record => record.id" :data-source="orderData"
        bordered>

        <!-- 订单状态 -->
        <template slot="status" slot-scope="data,record">
          <a-tag :color="statusColor[record.status]" style="margin-right:0">
            {{record.status|order_statusFilter}}
          </a-tag>
        </template>

        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

          <span>

            <a-popconfirm title="确认关联该订单吗?" @confirm="() => saveSetOrder(data)" style="flex-grow: 1;">
              <a-button size="small" type="primary">关联</a-button>
            </a-popconfirm>

          </span>

        </template>
      </a-table>
    </a-modal>

    <!-- 回复邮件 -->
    <a-modal title="回复邮件" width="1200px" class="seeModal leftModal" :visible="sendEmailVisible"
      :confirm-loading="sendEmailLoading" @cancel="cancelSendEmail">
      <template slot="footer">


        <a-button key="submit" type="primary" @click="sendEmail" style="margin-left:16px"
          :disabled="replyEmailData==''||replyEmailData=='<p><br></p>'">
          确认
        </a-button>
        <a-button type="primary" @click="saveDraftEmail">
          保存草稿
        </a-button>
      </template>
      <div style="margin-bottom:4px">
        <a-button type="primary" size="small" v-for="(item, index) in replyTemplateList[0]" :key="item.name"
          style="margin:4px" @click="replyTemplate(item.value)">{{item.name}}</a-button>
      </div>
      <div style="margin-bottom:4px">
        <a-button type="primary" size="small" v-for="(item, index) in replyTemplateList[1]" :key="item.name"
          style="margin:4px" @click="replyTemplate(item.value)">{{item.name}}</a-button>
      </div>
      <div style="margin-bottom:16px">
        <a-button type="primary" size="small" v-for="(item, index) in replyTemplateList[2]" :key="item.name"
          style="margin:4px" @click="replyTemplate(item.value)">{{item.name}}</a-button>
      </div>


      <div style="border: 1px solid #ccc;" v-if="sendEmailVisible">
        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" />
        <Editor style="height: 400px; overflow-y: hidden;" v-model="replyEmailData" :defaultConfig="editorConfig"
          :uploadImage="uploadImage" @onChange="changeValue(editor.getHtml(),'obj','replyEmailData')"
          @onCreated="onCreated" />
        <!-- @onChange="changeValue($event.target.value,'obj','replyEmailData')" @onCreated="onCreated" /> -->
      </div>
    </a-modal>
  </div>
</template>
<script>
  import { set_replied_status, get_email_draft, save_email_draft, getOrderList, Batch_assign, set_remark, set_status, orderDelete, search_order, to_association, send_email, to_association_email, update_products, update_address } from "@/axios/home.js"
  import { getUserList } from "@/axios/user.js"
  import { IEditorConfig, Editor, Toolbar } from '@wangeditor/editor-for-vue'
  import { debounce } from '@/static/js/public';
  import { getCookie } from '@/static/js/cookie';
  import { myMixin } from "@/static/js/mixin.js"
  import moment from 'moment';
  const columnsSee = [
    {
      align: 'center',
      title: '产品ID',
      dataIndex: 'product_id',
    },
    {
      align: 'center',
      title: '产品图片',
      dataIndex: 'product_image',
      scopedSlots: { customRender: 'img', },
    },
    {
      align: 'center',
      title: '产品名称',
      dataIndex: 'product_name',
      scopedSlots: { customRender: 'product_name', },
    },
    {
      align: 'center',
      title: '产品价格',
      dataIndex: 'product_price',
    },
    {
      align: 'center',
      title: '产品数量',
      dataIndex: 'product_quantity',
      scopedSlots: { customRender: 'product_quantity', },
    },


  ];
  const orderColumns = [
    {
      align: 'center',
      title: '订单ID',
      dataIndex: 'order_id',
    },
    {
      align: 'center',
      title: '域名',
      dataIndex: 'site_url',
      scopedSlots: { customRender: 'site_url', },
    },
    {
      align: 'center',
      title: '客户邮箱',
      dataIndex: 'customer_email',
    },

    {
      align: 'center',
      title: '状态',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status', },
    },

    {
      align: 'center',
      title: '操作',
      // dataIndex: 'status',
      scopedSlots: { customRender: '操作', },
    },
  ];
  const columns = [//列描述
    {
      align: 'center',
      title: 'ID',
      dataIndex: 'id',
    },

    {
      align: 'center',
      title: '客户名称',
      dataIndex: 'history',
      scopedSlots: { customRender: 'history', },
    },
    {
      align: 'center',
      title: '客户邮箱',
      dataIndex: 'customer_email',
      scopedSlots: { customRender: 'customer_email', },
    },
    {
      align: 'center',
      title: '邮件日期',
      dataIndex: 'max_email_time',
    },
    {
      align: 'center',
      title: '邮件状态',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status', },
    },
    {
      align: 'center',
      title: '订单状态',
      dataIndex: 'order_status',
      scopedSlots: { customRender: 'order_status', },
    },
    {
      align: 'center',
      title: '操作',
      // dataIndex: 'status',
      scopedSlots: { customRender: '操作', },
    },

  ];
  export default {
    components: { Editor, Toolbar },
    filters: {

      statusFilter(status) {
        const statusMap = {
          "0": '待处理',
          "1": '已回复',

        }

        return statusMap[status]

      },
      order_statusFilter(status) {
        const statusMap = {
          "2": '待处理',
          "3": '已发催单邮件',
          "8": '已发转卡邮件',
          "5": '完成',
          "6": '弃单',
          "7": '已发确认邮件',
          '9': '高风险',
          '10': '已发链接邮件'
        }

        return statusMap[status]

      }

    },
    mixins: [myMixin],
    data() {

      return {
        replyTemplateList: [
          [
            {
              name: '要付款凭证',
              value: `お客様
ご注文ありがとうございます。
お手数ですが、お振込明細書を添付ください。 
ご多忙のところ恐縮ですが、ご返答いただければ幸いです。`
            },
            {
              name: '确认收款',
              value: `お客様、お世話になっております。 
振込み確認とれました。本日、商品手配と発送準備を致します。 
発送しましたら、こちらから再度ご連絡させていただきます。 
ご安心ください。宜しくお願いします。`
            },
            {
              name: '稍后发银行',
              value: `お客様
ご返信が遅くなりましたこと、申し訳ございませんでした。

後ほど振込先をお知らせいたします。

ご検討のほどよろしくお願い致します。
ありがとうございました。`
            },
            {
              name: '付款后联系我们',
              value: `お客様

このたびは､当店をご利用いただき誠にありがとうございます。

お支払い後、ご連絡いただければ幸いです。 
ご連絡後、迅速にお届けを手配させていただきます。

ご検討のほどよろしくお願い致します。
ありがとうございました。
`
            },
            {
              name: '发货时间',
              value: `お客様 

ご返信が遅くなりましたこと、申し訳ございませんでした。

振り込んだ後、商品は配送準備に2日かかります。配送業者はヤマト運輸になります。
その日より2-3日間以内お届けできる予定ですので、ご了承ください。

他にもご不明な点がございましたら、いつでもお問合せ下さいませ。

ご検討のほどよろしくお願い致します。
ありがとうございました。`
            },
            {
              name: '只接受银行转账',
              value: `お客様
いつもお世話になっております。
この度弊店をご利用頂き誠にありがとうございます。
恐れ入りますが、現時点弊店でのお支払の方は銀行のお振込のみとなっておりますが、ご了承くださいませ。
ご迷惑かけまして申し訳ございません。
どうぞよろしくお願い申し上げます。`
            },
            {
              name: '指定送达日期时',
              value: `お客様、お世話になっております。
ご連絡ありがとうございます。
ご注文商品はご指定時間内にお届け先まで配達することができます。
ご安心ください。よろしくお願いします。`
            },
            {
              name: '重发银行信息',
              value: `お客様

ご注文を確かに承りました。
銀行カードの情報はメールでお客様に送信いたしました。
万一、メールが届かない場合は、大変お手数ではございますがもう一度お問い合わせください。
今後ともご愛顧賜りますようよろしくお願い申し上げます。`
            },
          ],
          [
            {
              name: '修改数量',
              value: `お客様

このたびは､当店をご利用いただき誠にありがとうございます。

ご注文の数量が変更になりました。
ご安心ください。

他にもご不明な点がございましたら、
いつでもお問い合わせください。
今後とも、弊社製品をよろしくお願いいたします。`
            },
            {
              name: '提供想更改的地址',
              value: `お客様
送り先変更可能です。
正しい送り先を教えていただけませんか。
こちらで備考させていただきます。
どうぞよろしくお願い申し上げます。`
            },
            {
              name: '修改地址',
              value: `お客様
このたびは､当店をご利用いただき誠にありがとうございます。

送り先変更の件、承りました。
それではこちらで、ご注文を変更処理させて頂きます。

ご検討のほどよろしくお願い致します。
ありがとうございました。
`
            },
            {
              name: '修改尺寸',
              value: `お客様

サイズ変更の件、承りました。
それではこちらで、ご注文を変更処理させて頂きます。
どうぞよろしくお願い申し上げます。`
            },
            {
              name: '修改颜色',
              value: `お客様

カラー変更の件、承りました。
それではこちらで、ご注文を変更処理させて頂きます。
どうぞよろしくお願い申し上げます。`
            },
            {
              name: '万能修改回复',
              value: `お客様
このたびは､当店をご利用いただき誠にありがとうございます。

変更の件、承りました。
それではこちらで備考させていただきます。
どうぞよろしくお願い申し上げます。

他にもご不明な点がございましたら、いつでもお問合せ下さいませ。

ご検討のほどよろしくお願い致します。
ありがとうございました。`
            },
            {
              name: '转卡',
              value: `お客様

ご迷惑をおかけして申し訳ありません。

私たちの業務上のミスにより、間違った口座情報をお客様に送信してしまいました。お忙しいところ大変申し訳ございません。
お客様がこの間違った口座に振り込みをしないようお願い申し上げます。
引き続き商品の購入をご希望の場合は、こちらからもう一度正しい口座情報をお送りいたします。`
            },
            {
              name: '库存',
              value: `お客様

このたびは､当店をご利用いただき誠にありがとうございます。

指定の商品は在庫ありますよ！
安心して購入してください。
何卒宜しくお願いいたします。`
            },
          ],
          [
            {
              name: '取消订单',
              value: `お客様

ご迷惑をおかけして申し訳ありません。
キャンセルの件、承りました。
それではこちらで、ご注文をキャンセル処理させて頂きます。
今後とも引き続きご愛顧のほど
どうぞよろしくお願い申し上げます。`
            },
            {
              name: '关于退换货',
              value: `お客様、お世話になっております。 
返品と交換はサポートしていますが。
返品と交換につきましては、商品到着後7日以内にご連絡ください。
ご迷惑をおかけして申し訳ありません。
他にもご不明な点がございましたら、いつでもお問合せ下さいませ。
ご検討のほどよろしくお願い致します。
ありがとうございました。`
            },
            {
              name: '退款',
              value: `お客様
ご指定銀行口座にお振込にてご返金致します。
返金先の口座をお知らせくださいませ。
下記の内容をご連絡ください。
銀行名 / 支店名 / 口座番号 / 口座名義人
返金に数日かかりますので、予めご了承くださいませ。`
            },
            {
              name: '问产品链接',
              value: `お客様
お伺いしたいことがございますが、
欲しい商品のリンクを教えていただけませんか。`
            },
            {
              name: '转账手续费',
              value: `お客様、お世話になっております。
銀行振込手数料は弊社負担となります。
ご安心ください。宜しくお願いします。`
            },
            {
              name: '为什么便宜',
              value: `お客様

このたびは､当店をご利用いただき誠にありがとうございます。

安いっていう理由は、特価だからです。
ご安心ください。
他にもご不明な点がございましたら、いつでもお問合せ下さいませ。

ご検討のほどよろしくお願い致します。
ありがとうございました。`
            },
            {
              name: '正品',
              value: `お客様、お世話になっております。 
弊店の商品は全部100％本物、不良品をもらえば、全額が送り返せます。 
ご安心ください。宜しくお願いします。 `
            },
            {
              name: '待发货1',
              value: `お客様

出荷は手配中です。少々お待ちください。追跡番号が出たら、お知らせします。`
            },
            {
              name: '待发货2',
              value: `お客様

ご迷惑をおかけして申し訳ありませんが、
ちょっと時間がかかります、もうちょっとお待ちいただけないでしょうか。
よろしくご了承下さいますようお願い申し上げます。 `
            },
            {
              name: '待发货3',
              value: `お客様

このたびは､当店をご利用いただき誠にありがとうございます。

お手紙ありがとうございます。最初の2日間のご注文が多すぎたため、本日はお届けできません。お支払いとお届けについては明日ご連絡させていただきます。遅くとも明後日ご連絡いたします。ご不便をおかけして申し訳ございません。ご理解のほどよろしくお願いいたします。`
            },
            {
              name: '新品',
              value: `お客様、お世話になっております。
弊店の商品は全部100％本物と新商品ですが、不良品をもらえば、全額が送り返せます。
ご安心ください。宜しくお願いします。`
            },
            {
              name: '因数量错误取消',
              value: `お客様

このたびは､当店をご利用いただき誠にありがとうございます。

ご注文の数量が変更になりました。
ご迷惑をおかけして申し訳ございません。この製品はまだ必要ですか。ご協力をお待ちしております。

他にもご不明な点がございましたら、いつでもお問合せ下さいませ。

ご検討のほどよろしくお願い致します。
ありがとうございました。`
            },
            {
              name: '网银转账凭证',
              value: `お客様 

ご迷惑をおかけして申し訳ありませんが、
ネットバンキング振込の場合は、振込明細をご確認いただけます。
オンラインバンキングにログインして記録を提供していただけますか?`
            },
          ],

        ],
        statusColor: {

          "0": 'orange',
          "1": 'green',//前两个是邮件状态，后面的是订单状态
          "2": 'orange',
          "3": 'pink',
          "4": 'blue',
          "5": 'green',
          "6": 'red',
          "7": 'cyan',
          "8": 'purple',
          "9": '#ff0000',
          "10": 'geekblue',
        },
        status: [

          {
            value: '2',
            label: '待处理'
          },
          {
            value: '3',
            label: '已发催单邮件'
          },
          {
            value: '7',
            label: '已发确认邮件'
          },
          {
            value: '8',
            label: '已发转卡邮件'
          },



        ],

        form: {
          domain: '',
          page_size: 50,
          page: 1,
          email: '',
          order_id: '',
          reply_status: '0',
          order_status: '',
          start_time: '',
          end_time: '',
          content: '',
          user_id: '',
        },
        userList: [],

        data: [],//数据
        pagination: {
          showQuickJumper: true,//是否快速跳转某页
          showSizeChanger: true,//是否可以改变条数
          pageSizeOptions: ['10', '20', '50', '100', '200'],
          pageSize: 50,
          showTotal: (total, range) => `当前显示第${range[0]}-${range[1]}，总条数：${total} `
        },//分页器
        loading: false,//是否加载中
        btnLoading: false,
        columns,//列描述
        tableSize: 'default',//表格尺寸

        seeModalVisible: false,//查看详情
        seeData: {},

        columnsSee,
        token: {
          role: ''
        },

        editAssignModalVisible: false,//批量分配
        editAssignConfirmLoading: false,
        editAssignForm: {
          service_id: '',
          customer_emails: '',
        },

        editStatusModalVisible: false,//设置状态
        editStatusConfirmLoading: false,
        editStatusForm: {
          status: '',
          transaction_id: '',
        },
        editAssignFormRules: {

          status: [
            { required: true, message: '请选择状态', trigger: 'change' },
          ],
        },

        editNameData: '',//新客户名称
        editPhoneData: '',
        editAddressData: '',//新地址
        emailData: [],//邮箱详情



        selectId: '',//选中行的ID，用来设置背景色

        setOrderVisible: false,//关联订单
        setOrderLoading: false,
        setOrderForm: {
          order_id: '',
          site_url: '',
        },
        orderData: [],
        orderColumns,
        id: '',//要关联的邮件id
        transaction_id: '',
        email_id: '',
        userList: [],
        changeValue: null,
        //富文本框
        editor: null,
        uploadImage: { server: '/api/upload-image' },
        sendEmailVisible: false,//设置状态
        sendEmailLoading: false,
        editor: null,
        replyEmailData: '',
        toolbarConfig: {},
        editorConfig: {
          placeholder: '请输入内容...',
          MENU_CONF: {
            uploadImage: {
              server: '/api/upload_image',
              headers: {
                Token: JSON.parse(getCookie("token")).token,
              },
              fieldName: 'image',

            }
          },

        },
        mode: 'default', // or 'simple'
      }

    },
    created() {
      this.token = JSON.parse(getCookie("token"))
      if (this.token.role == 'super') {
        getUserList(({ page: 1, page_size: 100, role: 'normal' })).then(res => {
          this.userList = res.data.list.map((item) => {
            return {
              label: item.username,
              value: item.id
            }
          })
          this.userList = [{ label: '未指派', value: '-1' }, ...this.userList]
        })
        if (this.columns[1].title != '客服') {
          this.columns.splice(1, 0, {
            align: 'center',
            title: '客服',
            dataIndex: 'username',

          },)
        }
        // this.form.reply_status = '';
        this.status = [...this.status,
        {
          value: '5',
          label: '完成'
        },
        {
          value: '6',
          label: '弃单'
        },
        {
          value: '9',
          label: '高风险'
        },
          {
            value: '10',
            label: '已发链接邮件'
          },
        ]
      }
      this.form.start_time = moment(moment().format("YYYY-MM-DD 00:00:00"))
      this.form.end_time = moment(moment().format("YYYY-MM-DD 23:59:59"))
      this.fetch();
      this.changeValue = debounce(this.editInput)


    },
    mounted() {

    },

    methods: {
      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      },
      // 添加防抖
      editInput(value, obj, ...keys) {
        if (keys.length == 1) {
          if (obj == 'obj') {
            this[keys[0]] = value
          } else {
            obj[keys[0]] = value
          }
        } else {
          this.editInput(value, this[keys[0]], ...keys.slice(1))
        }

      },

      getCheckboxProps(record) {
        return {
          props: { disabled: record.status != 2 }
        }

      },
      customRow(data) {
        return {
          on: {
            click: () => {
              let no = ['button', 'textarea', 'a', "input"];
              if (no.includes(event.target.localName) || data.history.length == 0) {
                return
              } else {
                this.emailData = [];
                this.emailData = data.history;
                this.selectId = data.id
                this.transaction_id = data.transaction_id
                this.email_id = data.history.find((item) => item.email_type == 'receive_inbox' || item.email_type == 'receive_spam')
                this.email_id = this.email_id.id
              }

            },        // 点击表头行
          }
        };

      },
      // showDrawer(data){
      //   this.emailData=data;
      // },
      reset(formName) {
        this.data = [];
        this.$refs[formName].resetFields();
        this.pagination.current = 1;
        this.form.page = 1
        this.form.start_time = moment(moment().format("YYYY-MM-DD 00:00:00"))
        this.form.end_time = moment(moment().format("YYYY-MM-DD 23:59:59"))
        this.fetch();
      },
      onChangeStartTime(date, dateString) {
        this.form.start_time = dateString
      },
      onChangeEndTime(date, dateString) {
        this.form.end_time = dateString
      },

      changeStatus(data) {
        this.form.page = 1;
        this.pagination.current = 1;
        this.fetch();
      },
      async fetch(params = {}) {
        this.loading = true;
        let start_time = moment(this.form.start_time).format("YYYY-MM-DD HH:mm:ss")
        let end_time = moment(this.form.end_time).format("YYYY-MM-DD HH:mm:ss")

        let data = await getOrderList({ ...params, ...this.form, start_time: start_time, end_time: end_time })

        const pagination = { ...this.pagination };
        pagination.total = data.data.total;
        this.data = data.data.list;
        this.pagination = pagination;
        this.loading = false;
        this.emailData = [];
        this.selectId = ''
      },
      //删除
      async onDelete(data) {
        let resule = await orderDelete({ id: data.id });
        if (resule.code == 200) {
          this.$message.success(resule.msg);
          this.fetch()
        } else {
          this.$message.error(resule.msg);
        }
      },



      //修改状态
      onEditStatus(data) {
        this.editStatusForm.status = data.order_status
        this.editStatusForm.transaction_id = data.transaction_id
        this.editStatusModalVisible = true
      },
      saveStatus() {
        this.$refs.editStatus.validate(async valid => {
          if (valid) {
            this.editStatusConfirmLoading = true;
            let resule = await set_status({ ...this.editStatusForm });
            if (resule.code == 200) {
              this.$message.success(resule.msg);
              this.editStatusModalVisible = false;
              this.editStatusConfirmLoading = false;
              this.$refs.editStatus.resetFields();
              this.fetch();
            } else {
              this.$message.error(resule.msg);
              this.editStatusConfirmLoading = false;
            }

          } else {
            return false;
          }
        });


      },
      cancelStatus() {
        this.$refs.editStatus.resetFields();
        this.editStatusModalVisible = false;
      },

      shopEmail() {
        // // this.activeKey==!this.activeKey
        // console.log(this.activeKey)
      },


      onSee(data) {
        this.seeData = data;
        this.emailData = [];
        this.emailData = data.history;
        this.transaction_id = data.transaction_id;
        this.seeModalVisible = true;
      },
      deleteQuantity(index) {
        this.seeData.products.splice(index, 1)
      },
      saveQuantity(data, type, index) {//修改数量

        this.seeData.order_price = this.seeData.products.reduce((pre, item, index) => {
          return pre + (item.product_price * item.product_quantity)
        }, 0)
        let d = {
          transaction_id: this.seeData.transaction_id,
          email_id: this.seeData.id,
          total_amount: this.seeData.order_price,
          products: this.seeData.products,
        }
        update_products(d).then((resule) => {
          if (resule.code == 200) {
            this.fetch();
            // data.editEmail=false;

            this.$message.success(resule.msg);
          } else {
            this.$message.error(resule.msg);
          }
        });

      },
      cancelQuantity() {
        this.editAddressData = '';
        this.editPhoneData = '';
        this.editNameData = '';
        this.seeData.editAddress = false;
        this.seeModalVisible = false;
      },
      //编辑地址
      editAddress() {
        this.editAddressData = this.seeData.customer_address;
        this.editNameData = this.seeData.customer_name;
        this.editPhoneData = this.seeData.customer_phone;
        this.$set(this.seeData, 'editAddress', true);
      },
      saveAddress() {
        update_address({
          transaction_id: this.seeData.transaction_id,
          customer_phone: this.editPhoneData,
          customer_name: this.editNameData,
          customer_address: this.editAddressData
        }).then((resule) => {
          if (resule.code == 200) {
            this.seeData.customer_address = this.editAddressData
            this.editAddressData = '';
            this.seeData.customer_name = this.editNameData
            this.editNameData = '';
            this.seeData.customer_phone = this.editPhoneData
            this.editPhoneData = '';
            this.seeData.editAddress = false;

            this.$message.success(resule.msg);
          } else {
            this.$message.error(resule.msg);
          }
        });

      },
      cancelAddress() {
        this.editAddressData = '';
        this.editPhoneData = '';
        this.editNameData = '';
        this.seeData.editAddress = false;
      },
      // 关联订单
      onSetOrder(data) {
        // this.seeData=data;
        // this.emailData=data;
        this.id = data.id
        this.setOrderVisible = true;
      },
      //搜索订单
      searchOrder() {
        if (this.setOrderForm.order_id == '') {
          this.$message.error('订单ID不能为空');
        } else if (this.setOrderForm.site_url == '') {
          this.$message.error("网站域名不能为空");
        } else {
          search_order(this.setOrderForm).then((data) => {

            this.orderData = data.data
          });
        }

      },
      resetOrder() {
        this.orderData = [];
        this.$refs.setOrder.resetFields();
      },
      // 保存关联
      async saveSetOrder(data) {

        this.setOrderLoading = true;
        let resule = await to_association({ id: this.id, transaction_id: data.transaction_id });
        if (resule.code == 200) {
          this.$message.success(resule.msg);

          this.setOrderVisible = false;
          this.setOrderLoading = false;
          this.fetch();
        } else {
          this.$message.error(resule.msg);
          this.setOrderLoading = false;
        }


      },
      setOrderCancel() {
        this.orderData = [];
        this.$refs.setOrder.resetFields();
        this.setOrderVisible = false;
      },
      // 更改邮箱
      onEditEmail(data) {
        this.$set(data, 'editEmail', true)

      },

      onSaveEmail(data) {
        this.$set(data, 'emailIconLoading', true);
        to_association_email({ id: data.id, customer_email: data.customer_email }).then((resule) => {
          if (resule.code == 200) {

            this.$message.success(resule.msg);
          } else {
            this.$message.error(resule.msg);
          }
          data.emailIconLoading = false;
        });
      },
      //邮件不处理
      notHandled(data) {
        this.$set(data, 'emailStatusLoading', true);
        set_replied_status({ transaction_id: data.transaction_id, customer_email: data.customer_email }).then((resule) => {
          if (resule.code == 200) {
            this.$message.success(resule.msg);
            this.fetch()
          } else {
            this.$message.error(resule.msg);
          }
          data.emailStatusLoading = false;
        });
      },


      //回复邮件
      onSendEmail(data) {
        get_email_draft(({ email_id: this.email_id })).then(res => {
          if (res.code == 200) {
            this.sendEmailVisible = true
            this.replyEmailData = res.data?.draft || '';
          } else {
            this.$message.error(res.msg);
          }
        })


      },
      sendEmail() {

        send_email({ customer_email: this.emailData[(this.emailData.length - 1)].sender_email, content: this.replyEmailData, transaction_id: this.transaction_id, email_id: this.email_id }).then((data) => {
          if (data.code == 200) {
            this.replyEmailData = '';
            this.$message.success(data.msg);
            this.sendEmailVisible = false;
            this.fetch();
          } else {
            this.$message.error(data.msg);
          }
        });

      },
      //保存草稿
      saveDraftEmail() {
        save_email_draft({ draft: this.replyEmailData, email_id: this.email_id }).then((data) => {
          if (data.code == 200) {
            this.replyEmailData = '';
            this.$message.success(data.msg);
            this.sendEmailVisible = false;
            this.fetch();
          } else {
            this.$message.error(data.msg);
          }
        });
      },
      cancelSendEmail() {
        this.replyEmailData = '';
        this.sendEmailVisible = false;
      },
      //快捷回复
      replyTemplate(value) {
        this.replyEmailData = value
      },
    }
  };
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
  @import "@/static/css/index.scss";

  .ant-table td {
    white-space: nowrap;
  }

  .ant-table td .greenBtn {
    background-color: #7ac141;
    border-color: #7ac141;
  }

  ::v-deep .ant-descriptions-item-label {
    width: 80px;
    padding: 10px;
    vertical-align: middle;
  }

  ::v-deep .mainContent .ant-form .ant-form-item .ant-btn {
    margin-left: -1px !important;
    width: auto;
  }

  .leftModal ::v-deep .ant-modal {
    margin: 0 auto 0 5% !important;
    width: 60% !important;
  }

  .lyq_top_border {
    padding: 12px
  }

  ::v-deep .ant-descriptions-item-content {
    word-break: break-all;
    padding: 12px;
    max-height: 400px;
    overflow-y: auto;
  }

  ::v-deep .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
  }

  ::v-deep .ant-collapse-content img {
    max-width: 100%;
  }

  ::v-deep .ant-collapse-content p,
  .ant-collapse-header p {
    margin-bottom: 0px;
  }

  ::v-deep .ant-table-tbody>tr>td {
    padding: 8px 4px;
  }

  ::v-deep .lyqTableBox.ant-descriptions-bordered .ant-descriptions-view>table {
    table-layout: fixed;
  }

  ::v-deep .is_top {
    background: #badae0;
    /* background: #ffc97d; */

    position: relative;

  }

  ::v-deep td::selection,
  ::v-deep td p::selection {
    background-color: #FF9933;
  }
</style>